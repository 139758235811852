export type VideoDimensions = {
    width: number;
    height: number;
}
export const PortraitVideoDimensions: VideoDimensions = {
    width: 1080,
    height: 1920
}
export const LandscapeVideoDimensions: VideoDimensions = {
    width: 1920,
    height: 1080
}

export const SquareVideoDimensions: VideoDimensions = {
    width: 1080,
    height: 1080
}

export const DEFAULT_VIDEO_DIMENSIONS: VideoDimensions = PortraitVideoDimensions;

export const AspectRatioTypes = {
    Square: "Square",
    Portrait: "Portrait",
    Landscape: "Landscape",
} as const;
export type AspectRatioType = typeof AspectRatioTypes[keyof typeof AspectRatioTypes];
export const AspectRatioTypeValues = Object.values(AspectRatioTypes);

export const AspectRatioTypeToDimensions: Record<AspectRatioType, VideoDimensions> = {
    Square: SquareVideoDimensions,
    Portrait: PortraitVideoDimensions,
    Landscape: LandscapeVideoDimensions,
}

export function getAspectRatioTypeFromDimensions(dimensions: VideoDimensions): AspectRatioType {
    if (dimensions.width === dimensions.height) {
        return "Square";
    } else if (dimensions.width > dimensions.height) {
        return "Landscape";
    } else {
        return "Portrait";
    }
}