"use client"
import React, {createContext, useContext, useEffect, useState} from "react";
import {VideoJob} from "@/models/VideoJob";
import {SocialConnectionStatus} from "@/app/social-actions";
import ScheduleVideoDialogContent from "@/app/social/components/schedule-video-dialog-content";


interface ScheduleVideoDialogContextProps {
    isDialogOpen: boolean;
    openDialog: (videoJob: VideoJob) => void;
    closeDialog: () => void;
}

const ScheduleVideoDialogContext = createContext<ScheduleVideoDialogContextProps | undefined>(undefined);

export default function ScheduleVideoDialogProvider({children, socialConnectionStatus}: {
    children: React.ReactNode,
    socialConnectionStatus: SocialConnectionStatus
}) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [videoJob, setVideoJob] = useState<VideoJob | undefined>(undefined);

    const openDialog = (videoJob: VideoJob) => {
        setVideoJob(videoJob);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setVideoJob(undefined);
    };

    return (
        <ScheduleVideoDialogContext.Provider value={{isDialogOpen, openDialog, closeDialog}}>
            {children}
            <ScheduleVideoDialogContent
                isOpen={isDialogOpen}
                onClose={closeDialog}
                videoJob={videoJob}
                socialConnectionStatus={socialConnectionStatus}
            />
        </ScheduleVideoDialogContext.Provider>
    );
};

export const useScheduleVideoDialogContext = () => {
    const context = useContext(ScheduleVideoDialogContext);
    if (!context) {
        throw new Error("useScheduleVideoDialogContext must be used within a ScheduleVideoDialogProvider");
    }
    return context;
}
