import {Dialog, DialogContent, DialogPortal, DialogTitle} from "@/components/ui/dialog";
import React from "react";
import {VideoJob} from "@/models/VideoJob";
import {SocialConnectionStatus} from "../../social-actions";
import SchedulePostContent from "./schedule-post-content";
import SocialCalendarSettingsDialog from "@/app/social/components/calendar/social-calendar-settings-dialog";

export type ScheduleVideoDialogContentProps = {
    isOpen: boolean;
    onClose: () => void;
    videoJob: VideoJob | undefined;
    socialConnectionStatus: SocialConnectionStatus;
}
export default function ScheduleVideoDialogContent({
                                                       isOpen,
                                                       onClose,
                                                       videoJob,
                                                       socialConnectionStatus
                                                   }: ScheduleVideoDialogContentProps) {

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogPortal>
                <DialogContent
                    className="dialog-content sm:max-w-full lg:max-w-screen-lg max-h-[90%] w-[90%] sm:w-auto h-auto overflow-y-scroll">
                    <DialogTitle>Publish Video</DialogTitle>
                    {videoJob &&
                        <SchedulePostContent socialConnectionStatus={socialConnectionStatus} videoJob={videoJob}/>}
                    {!videoJob && <div>Video Job not found</div>}
                </DialogContent>
            </DialogPortal>
        </Dialog>
    );
}
