import {ProjectChapterFields} from "@/models/UserProject";
import {BaseEntity} from "@/models/BaseEntity";
import {UploadedFile} from "@/models/UploadedFile";
import {DEFAULT_VIDEO_DIMENSIONS, VideoDimensions} from "@/models/VideoDimensions";
import {ImageResource, VideoResource} from "@/models/VideoJob";
import {TemplateType} from "@/app/projects/template-types";
import {MusicTrack} from "@/models/MusicTrack";


export type TemplateScript = {
    title: string;
    script: string;
}

export const ProjectTemplateTransitionTypes = {
    MixMatch: "MixMatch",
    AvatarOnly: "AvatarOnly",
    AvatarWithMedia: "AvatarWithMedia",
    MediaOnly: "MediaOnly",
} as const;
export type ProjectTemplateTransitionType = typeof ProjectTemplateTransitionTypes[keyof typeof ProjectTemplateTransitionTypes];
export const ProjectTemplateTransitionTypeValues = Object.values(ProjectTemplateTransitionTypes);

export type ProductDetails = {
    name: string;
    description: string;
    files: UploadedFile[];
    coverPreviewFile: UploadedFile | null;
    sourceUrl?: string;
}

export type AvatarHookSelections = Record<string, string>;
export type ProductVideoTemplateFields = {
    uid: string;
    dimensions: VideoDimensions;
    language: "English";

    avatarIds: string[]; // this will determine how many Projects there will be created
    avatarHookSelections: AvatarHookSelections; // only allow one avatar to one hook
    chapters: Partial<ProjectChapterFields>[];
    targetLengthSeconds: number;
    transitionType: ProjectTemplateTransitionType;
    addSubtitle: boolean;
    isTrial?: boolean;


    webContentUrl?: string;
    styles?: {
        [key in ContentStyleType]?: { chapters: Partial<ProjectChapterFields>[] };
    }
    useHookSpeech: boolean;
    hookSpeech: string | null;
    hookSpeechOptions: {
        [key in ContentStyleType]?: { hookSpeech: string };
    }
    productId: string | null;
    projectNamePrefix?: string; // this will be used to prefix the project name, we will start with the current local time of the user
    templateType?: TemplateType;
    bgMusic?: MusicTrack;
    userProvidedAudio?: UploadedFile; // this is the user provided audio that will be used for the video. if not defined we will default to script mode
    // description: string;
    // name: string;
    // files: UploadedFile[];
    // coverPreviewFile: UploadedFile | null;
    // sourceUrl?: string;
} & ProductDetails;

export type ProductVideoTemplate = ProductVideoTemplateFields & BaseEntity;

export function templateToScriptContent(template: ProductVideoTemplateFields): string {
    let content = "";
    if (template.useHookSpeech && template.hookSpeech) {
        content += template.hookSpeech.trim();
        content += "\n\n";
    }

    for (const chapter of template.chapters) {
        content += chapter.content?.trim();
        content += "\n\n";
    }
    content.trimEnd();
    return content;

}

export const DefaultTimeLengthSeconds = 15;
export type ProjectTemplateCommonFields = {
    uid: string;
}
export type BaseProjectTemplateRequest = {} & ProjectTemplateCommonFields;

export type BaseProjectTemplateResponse = {
    success: boolean;
    errorMessage?: string;
} & ProjectTemplateCommonFields;

export type WebExtractRequest = {
    url: string;
    timeLengthSeconds: number;
    userSpecifiedProductName?: string;
    userSpecifiedDescription?: string;
    extractMedia?: boolean;
} & BaseProjectTemplateRequest;


export type WebMediaContent = VideoResource | ImageResource;
export type WebExtractResponse = {
    title: string;
    // paragraphs: string[];
    extractedAt: number;
    description: string;
    webContents?: WebMediaContent[];
} & BaseProjectTemplateResponse;


export const ContentStyleTypes = {
    Business: "Business",
    Playful: "Playful",
    Catchy: "Catchy",
    Curious: "Curious",
} as const;
export type ContentStyleType = typeof ContentStyleTypes[keyof typeof ContentStyleTypes];
export const ContentStyleTypeValues = Object.values(ContentStyleTypes);

export type ContentStyleAdjustmentRequest = {
    title: string;
    paragraphs: string[];
    contentStyleAdjustmentType: ContentStyleType;
    timeLengthSeconds: number;
} & BaseProjectTemplateRequest;

export type ContentStyleAdjustmentResponse = {
    paragraphs: string[];
    adjustedAt: number;
    errorMessage?: string;
} & BaseProjectTemplateResponse;

export type DescriptionToContentParagraphsRequest = {
    title: string;
    description: string;
    timeLengthSeconds: number;
    contentAdjustmentType?: ContentStyleType;
} & BaseProjectTemplateRequest;

export type HookSpeechRequest = {
    title: string;
    description: string;
    url?: string;
    timeLengthSeconds: 2; // it can only be 2 seconds for now
    contentAdjustmentType?: ContentStyleType;
} & BaseProjectTemplateRequest;

export type HookSpeechResponse = {
    hookSpeech: string;
    styleAdjustmentType?: ContentStyleType;
} & BaseProjectTemplateResponse;

export type DescriptionToContentParagraphsResponse = {
    paragraphs: string[];
    styleAdjustmentType?: ContentStyleType;
} & BaseProjectTemplateResponse;

export type DescriptionToParagraphsWithStylesResponse = {
    [key in ContentStyleType]?: { paragraphs: string[] };
} & BaseProjectTemplateResponse;

export type HookSpeechResponseWithStylesResponse = {
    [key in ContentStyleType]?: { hookSpeech: string };
} & BaseProjectTemplateResponse;

export function hasStyledContent(template: ProductVideoTemplateFields): boolean {
    if (!template.styles) {
        return false;
    }
    for (const style of ContentStyleTypeValues) {
        if (!template.styles?.[style]) {
            return false;
        }
    }
    return true;
}

export const MINIMUM_VALID_TEMPLATE_DESCRIPTION_LENGTH = 50;

export function defaultProjectTemplateFields(uid: string, templateType: TemplateType | undefined = undefined): ProductVideoTemplateFields {
    return {
        name: "",
        uid,
        description: "",
        avatarIds: [],
        avatarHookSelections: {},
        chapters: [],
        dimensions: DEFAULT_VIDEO_DIMENSIONS,
        targetLengthSeconds: DefaultTimeLengthSeconds,
        transitionType: ProjectTemplateTransitionTypes.MixMatch,
        language: "English",
        hookSpeech: null,
        hookSpeechOptions: {},
        addSubtitle: true,
        productId: null,
        useHookSpeech: true,
        files: [],
        coverPreviewFile: null,
        templateType: templateType,
    }
}

export function webContentExtractionResponseToProjectTemplateFields(response: WebExtractResponse) {
    // const chapters = response.paragraphs.map((p, i) => ({
    //     name: `Chapter ${i + 1}`,
    //     content: p,
    //     order: i,
    // }));

    const newTemplate: ProductVideoTemplateFields = {
        uid: response.uid,
        name: response.title,
        avatarIds: [],
        avatarHookSelections: {},
        chapters: [], // start with empty chapters
        dimensions: DEFAULT_VIDEO_DIMENSIONS,
        targetLengthSeconds: DefaultTimeLengthSeconds,
        transitionType: ProjectTemplateTransitionTypes.MixMatch,
        language: "English",
        description: response.description,
        hookSpeechOptions: {},
        hookSpeech: null,
        addSubtitle: false,
        productId: null,
        useHookSpeech: false,
        files: [],
        coverPreviewFile: null,
    }
    return newTemplate;
}

export function projectTemplateChaptersToTextContent(template: ProductVideoTemplate) {
    let content = "";
    for (const chapter of template.chapters) {
        content += chapter.content?.trim();
        content += "\n\n";
    }
    content.trimEnd();
    return content;
}


export function isProjectTemplateContentValid(template: ProductVideoTemplateFields): boolean {
    if (template.description.length < MINIMUM_VALID_TEMPLATE_DESCRIPTION_LENGTH) {
        return false;
    }

    return true;
}
