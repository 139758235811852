import {BaseEntity} from "@/models/BaseEntity";

export type SocialPostStatus = "scheduled" | "published" | "failed" | "cancelled";
export type SocialPostPlatform = "tiktok" | "facebook" | "instagram";

export function socialPlatformName(platform: SocialPostPlatform): string {
    switch (platform) {
        case "tiktok":
            return "TikTok";
        case "facebook":
            return "Facebook";
        case "instagram":
            return "Instagram";
        default:
            return "";
    }
}

export type SocialPostSchedule = {
    uid: string; // the user ID of the creator
    platform: SocialPostPlatform;
    platformPostId?: string; // to be updated by the backend after the post is created
    scheduledAt: number; // epoch time in seconds
    content: string; // the content of the post
    videoUrl: string; // the URL of the video to be posted
    status: SocialPostStatus; // the status of the scheduled post
    scheduleId?: string; // a unique identifier for the scheduling system
    systemMessage?: string; // a message from the scheduling system (e.g., QStash)
} & BaseEntity;

export function getSocialPostScheduleCallbackUrl(baseUrl: string, scheduledPost: SocialPostSchedule): string {
    return `${baseUrl}/api/webhook/qstash/social/schedule/${scheduledPost.uid}/${scheduledPost.id}`;
}
