"use client";

import {postVideoOnSocialPlatform, schedulePost, SocialConnectionStatus} from "@/app/social-actions";
import {Button} from "@/components/ui/button";
import {useCallback, useState} from "react";
import {Textarea} from "@/components/ui/textarea";
import {DateTimePicker} from "@/components/ui/date-time-picker";
import {socialPlatformName, SocialPostPlatform} from "@/models/SocialPostSchedule";
import {getJobOutputUrl, VideoJob} from "@/models/VideoJob";
import {useUserContext} from "@/lib/hooks/use-user-context";
import {toast} from "sonner";
import TikTok from "@/assets/tiktok.svg";
import {CalendarClock, ChevronLeft, Instagram, Rss, Share2} from "lucide-react";
import Link from "next/link";
import {Spinner} from "@/components/ui/spinner";
import {SocialOAuthProvider} from "@/services/interfaces/OAuthAdapter";
import {Label} from "@radix-ui/react-label";
import SocialCalendarSettingsDialog from "@/app/social/components/calendar/social-calendar-settings-dialog";
import {UserFacingError} from "@/models/errors/UserFacingError";
import {cn} from "@/lib/utils";

export type SchedulePostContentProps = {
    socialConnectionStatus: SocialConnectionStatus;
    videoJob: VideoJob;
}
export default function SchedulePostContent({socialConnectionStatus, videoJob}: SchedulePostContentProps) {

    let nearest5Minutes = new Date();
    const minutes = nearest5Minutes.getMinutes();
    const newMinuteCount = (Math.ceil(minutes / 5) + 1) * 5;
    nearest5Minutes = new Date(nearest5Minutes.getTime() + (newMinuteCount - minutes) * 60 * 1000);

    const [mode, setMode] = useState<undefined | "post" | "schedule">(undefined);
    const [date, setDate] = useState<Date | undefined>(nearest5Minutes);
    const [caption, setCaption] = useState<string | undefined>(undefined);
    const {user} = useUserContext();
    const [processing, setProcessing] = useState<Set<SocialPostPlatform>>(new Set<SocialPostPlatform>());
    const [postError, setPostError] = useState<Map<SocialPostPlatform, string>>(new Map<SocialPostPlatform, string>());
    const [showMissingCaption, setShowMissingCaption] = useState(false);
    const [showMissingDate, setShowMissingDate] = useState(false);
    const [anyPostScheduled, setAnyPostScheduled] = useState(false);


    const resetErrors = useCallback(() => {
        setShowMissingCaption(false);
        setShowMissingDate(false);
        setPostError(new Map<SocialPostPlatform, string>()); // reset error message
    }, []);

    const handleSchedule = useCallback(async (videoUrl: string, caption: string, date: Date, platform: SocialPostPlatform) => {
        const epochSeconds = Math.floor(date.getTime() / 1000);
        try {
            await schedulePost(platform, caption, epochSeconds, videoUrl, videoJob.id);
            toast.success(`Scheduled ${socialPlatformName(platform)} post successfully`);
        } catch (err) {
            toast.error(`Failed to schedule ${socialPlatformName(platform)} post. Please try again.`);
        }
    }, [videoJob])


    const selectPlatform = (
        <div className={"flex flex-col p-4 gap-2"}>
            <video controls src={getJobOutputUrl(videoJob)} className={"h-[400px]"}/>
            <Button onClick={() => setMode("post")} className={"bg-blue-500"}>
                <Share2 className={"mr-2"}/>Post Now
            </Button>
            <Button variant={"secondary"} onClick={() => setMode("schedule")}>
                <CalendarClock className={"mr-2"}/>
                Schedule Post
            </Button>
        </div>);

    const socialMediaButtons = useCallback((oauthPlatform: SocialOAuthProvider, socialPlatform: SocialPostPlatform, available: boolean) => {
        const isBusy = processing?.has(socialPlatform);
        const busyIndicator = isBusy && <Spinner className={"mr-2"}/>;
        if (available) {
            return <>
                <Button onClick={async () => {
                    // reset error message
                    resetErrors();

                    if (!user) {
                        toast.error("User not authenticated. Please sign in.");
                        return;
                    }

                    if (!date) {
                        toast.error("Missing post time. Please select a date and time.");
                        setShowMissingDate(true);
                        return;
                    }

                    if (!caption) {
                        toast.error("Missing caption. Please enter a caption.");
                        setShowMissingCaption(true);
                        return;
                    }

                    const videoUrl = getJobOutputUrl(videoJob);
                    if (!videoUrl) {
                        toast.error("Video URL not found, cannot schedule post.");
                        return;
                    }
                    if (isBusy || !mode || !caption || !videoJob || !videoUrl) {
                        toast.error("Cannot create post. Missing required fields.");
                        return;
                    }
                    try {
                        setProcessing((prev) => new Set(prev).add(socialPlatform));
                        if (mode === "post") {
                            const response = await postVideoOnSocialPlatform(socialPlatform, caption, videoUrl);
                            if (response.success) {
                                toast.success(`Posted ${socialPlatformName(socialPlatform)} post successfully`);
                            } else {
                                const errorMessage = response.error;
                                setPostError((prev) => {
                                    prev.set(socialPlatform, errorMessage);
                                    return new Map(prev);
                                });
                                toast.error(errorMessage);
                            }
                        } else if (mode === "schedule") {
                            await handleSchedule(videoUrl, caption, date, socialPlatform);
                            setAnyPostScheduled(true);
                        }
                    } catch (e) {
                        console.error(e);
                        const errorMessage = `Failed to schedule/post. ${e}`;
                        setPostError((prev) => {
                            prev.set(socialPlatform, errorMessage);
                            return new Map(prev);
                        });
                        toast.error(errorMessage);
                    } finally {
                        setProcessing((prev) => {
                            prev.delete(socialPlatform)
                            return new Set(prev);
                        });
                    }

                }} className={"flex-grow"} disabled={isBusy}>
                    {
                        !isBusy && socialPlatform === "tiktok" && <TikTok className={"w-5 h-5 mr-2"}/>
                    }
                    {
                        !isBusy && socialPlatform === "instagram" && <Instagram size={16} className={"mr-2"}/>
                    }
                    {busyIndicator}
                    {mode === "post" && "Post Now on "}
                    {mode === "schedule" && "Schedule on "}
                    {socialPlatformName(socialPlatform)}
                </Button>
                <div className={"flex justify-center"}>
                    <Label
                        className={cn("text-red-500", "text-xs", postError.get(socialPlatform) ? "" : "hidden")}>{postError.get(socialPlatform)}</Label>
                </div>
            </>
        }
        return <Link href={`/api/oauth/${oauthPlatform}/signin`}>
            <Button className={"w-full"} variant={"secondary"}
                    disabled={isBusy}>
                {busyIndicator}Connect {socialPlatformName(socialPlatform)}
            </Button>
        </Link>
    }, [caption, date, handleSchedule, mode, postError, processing, resetErrors, user, videoJob])

    return (
        <div className={"flex flex-col gap-1 justify-center w-full md:w-[500px]"}>
            {!mode && selectPlatform}
            {
                mode && <>
                    <div className={"m-0 mt-[-15px]"}>
                        <Button variant={"link"}
                                onClick={() => {
                                    setMode(undefined);
                                    resetErrors();
                                }}
                                className={"text-xs p-0"}>
                            <ChevronLeft size={12}/>Back
                        </Button>
                    </div>
                    <Label className={"text-sm"} htmlFor="caption">Caption</Label>
                    <Label className={cn(showMissingCaption ? "" : "hidden")}>
                        <span className={"text-red-500 text-sm"}>Caption is required</span>
                    </Label>
                    <Textarea
                        id="caption"
                        className="col-span-3 min-h-24"
                        name={"caption"}
                        value={caption}
                        onChange={(e) => {
                            if (e.target.value.length > 0) {
                                setShowMissingCaption(false);
                            }
                            setCaption(e.target.value)
                        }}
                    />
                    {mode === "schedule" &&
                        <div className={"mt-5"}>
                            <Label className={"text-sm pb-0"} htmlFor="schedule-date">Schedule Date and Time</Label>
                            <br/>
                            <Label className={"text-xs p-0 text-muted-foreground"}>Schedule this post at your local time.
                                Current time is {new Date().toLocaleTimeString()}</Label>
                            <Label className={cn(showMissingDate ? "" : "hidden")}>
                                <br/>
                                <span className={"text-red-500 text-sm"}>Missing date</span>
                            </Label>
                            <DateTimePicker date={date} onChange={(date) => {
                                if (date) {
                                    setShowMissingDate(false);
                                }
                                setDate(date);
                            }}/>
                        </div>
                    }
                    <div className={"flex flex-col w-full gap-1.5 mt-4 justify-end"}>
                        {mode === "schedule" &&
                            <div>
                                <Label className={"text-xs pb-0 text-muted-foreground text-center"}>*To ensure your social
                                    account(s) are configured properly, try posting manually before scheduling.</Label>
                            </div>
                        }
                        {socialMediaButtons("tiktok", "tiktok", socialConnectionStatus.hasTikTok)}
                        {socialMediaButtons("facebook", "instagram", socialConnectionStatus.hasInstagram)}
                        {mode === "schedule" &&
                            <div className={cn("flex justify-center w-full", anyPostScheduled ? "" : "hidden")}>
                                <Link href={"/social"}>
                                    <Button variant={"link"} size={"sm"}>
                                        <CalendarClock className={"mr-1 w-4 h-4"}/>
                                        Go to Schedule
                                    </Button>
                                </Link>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}
