import {BaseEntity} from "@/models/BaseEntity";
import {ContentProcessStatusType, ContentProcessStatusTypes} from "@/models/ContentProcessStatusTypes";
import {epochNow} from "@/lib/date-utils";
import shortUUID from "short-uuid";
import {DEFAULT_VIDEO_DIMENSIONS, VideoDimensions} from "@/models/VideoDimensions";
import {AvatarEntry} from "@/models/Avatar";
import {DEFAULT_PROCESS_ALLOWED_MINUTES} from "@/configs/constants";
import {ProductVideoTemplate, templateToScriptContent} from "@/models/ProductVideoTemplate";
import {getContentLengthCalculator} from "@/lib/shared-ioc-container";
import {MusicTrack} from "@/models/MusicTrack";

export type ContentProcessStateOutput<TOutput> = ({
    status: typeof ContentProcessStatusTypes.Pending | typeof ContentProcessStatusTypes.Created | typeof ContentProcessStatusTypes.InProgress;
} | {
    status: typeof ContentProcessStatusTypes.Completed;
    output: TOutput
} | {
    status: typeof ContentProcessStatusTypes.Error;
    errorMessage: string;
});

export type VideoJob = BaseEntity & {
    uid: string;
    name: string;
    templateId: string;
    segments: VideoSegmentJob[];
    videoDimensions: VideoDimensions;
    avatar?: AvatarEntry;
    avatarContents: AvatarContents;
    script: string;
    processExpiresAt: number;
    enableSubtitle?: boolean;
    systemMessage?: string;
    expectedScriptReadingTime?: number;
    watermark?: string;
    bgMusic?: MusicTrack;
} & ContentProcessStateOutput<VideoResource>;

export type AvatarContents = {
    aiVoice?: ContentProcessStateOutput<AudioResource>;
    lipsyncVideo?: ContentProcessStateOutput<VideoResource>;
};

export function newEmptyVideoJob(uid: string): VideoJob {
    return {
        name: "",
        uid: uid,
        templateId: "",
        id: shortUUID().new(),
        createdAt: epochNow(),
        updatedAt: epochNow(),
        segments: [],
        videoDimensions: DEFAULT_VIDEO_DIMENSIONS,
        status: ContentProcessStatusTypes.Created,
        avatarContents: {},
        script: "",
        avatar: undefined,
        processExpiresAt: epochNow() + DEFAULT_PROCESS_ALLOWED_MINUTES * 60, // epoch seconds
        enableSubtitle: false,
    };
}

export function newVideoJob(template: ProductVideoTemplate, avatar: AvatarEntry): VideoJob {
    const script = templateToScriptContent(template);

    return {
        name: `${template.name} - ${avatar.name}`,
        uid: template.uid,
        templateId: template.id,
        id: shortUUID().new(),
        createdAt: epochNow(),
        updatedAt: epochNow(),
        segments: [],
        videoDimensions: template.dimensions || DEFAULT_VIDEO_DIMENSIONS,
        status: ContentProcessStatusTypes.Created,
        avatarContents: {},
        script,
        avatar,
        processExpiresAt: epochNow() + DEFAULT_PROCESS_ALLOWED_MINUTES * 60, // epoch seconds
        enableSubtitle: template.addSubtitle,
    };
}

export function addVideoSegmentToVideoJob(videoJob: VideoJob, segment: VideoSegmentJob) {
    videoJob.segments.push(segment);
}

export type VideoSegmentJob = BaseEntity & {
    order: number;
    type: VideoSegmentJobType;
    userUpload?: VideoResource | ImageResource;
    script: string;
    dependencies: SubVideoJob[]; // Sub jobs that need to be completed before this job can start
    subtitlePosition?: "bottom" | "top" | "middle";
} & ContentProcessStateOutput<VideoResource>;


export function newVideoSegmentJob(order: number, type: VideoSegmentJobType, userUpload?: VideoResource | ImageResource): VideoSegmentJob {
    return {
        order,
        id: shortUUID().new(),
        createdAt: epochNow(),
        updatedAt: epochNow(),
        type,
        userUpload,
        script: "",
        subtitlePosition: undefined,
        dependencies: [],
        status: ContentProcessStatusTypes.Pending,
    };
}

/**
 * Check if the job has timed out
 * @returns undefined if the job is either completed or errored out, true if the job has timed out
 * @param job
 */
export function jobProcessTimeout(job: {
    status: ContentProcessStatusType,
    processExpiresAt: number
}): boolean | undefined {
    if (job.status === ContentProcessStatusTypes.Completed || job.status === ContentProcessStatusTypes.Error) {
        // already completed or errored
        return undefined;
    }
    return job.processExpiresAt < epochNow();
}

/**
 * Check if the job has reached a terminal state
 * @param job
 */
export function jobReachesTerminalState(job: { status: ContentProcessStatusType, processExpiresAt: number }): boolean {
    return jobProcessTimeout(job) || job.status === ContentProcessStatusTypes.Completed || job.status === ContentProcessStatusTypes.Error;
}

export function jobInIntermediaryState(job: { status: ContentProcessStatusType }): boolean {
    return job.status === ContentProcessStatusTypes.Pending || job.status === ContentProcessStatusTypes.Created || job.status === ContentProcessStatusTypes.InProgress;
}

/**
 * Get the output url of a job if it has completed and the output is a url
 * @param job
 */
export function getJobOutputUrl(job: VideoJob): string | undefined {
    if (job.status === ContentProcessStatusTypes.Completed && job.output.contentType === "url") {
        return job.output.url;
    }
    return undefined;
}

type BaseMediaResource = { expiresAt?: number; byteLength?: number; isWebExtracted?: boolean } & ({
    contentType: "url";
    url: string;
} | {
    contentType: "identifier";
    identifier: string;
});

export type AudioResource = BaseMediaResource & {
    type: "audio";
}

export type VideoResource = BaseMediaResource & {
    type: "video";
}

export type ImageResource = BaseMediaResource & {
    type: "image";
}

export const VideoSegmentJobTypes = {
    Avatar: "Avatar",
    Pip: "Pip",
    Voiceover: "Voiceover",
} as const;
export type VideoSegmentJobType = typeof VideoSegmentJobTypes[keyof typeof VideoSegmentJobTypes];
export const VideoSegmentJobTypeValues = Object.values(VideoSegmentJobTypes);

/**
 * A sub job that is part of a video job
 */
export type SubVideoJob = BaseEntity & SubVideoJobInputs & ContentProcessStateOutput<VideoResource> & {
    extId?: string;
};

type SubVideoJobInputs = {
    type: typeof VideoSegmentJobTypes.Avatar;
    avatarVideo: VideoResource;
} | {
    type: typeof VideoSegmentJobTypes.Pip;
    avatarVideo: VideoResource;
    mainVideo: VideoResource;
} | {
    type: typeof VideoSegmentJobTypes.Voiceover;
    video: VideoResource;
}


/**
 * Get the percentage of completion of a video job
 * @param job
 */
export function videoJobProcessPercentage(job: VideoJob): number {
    if (job.status === ContentProcessStatusTypes.Completed) {
        return 100;
    }
    if (job.status === ContentProcessStatusTypes.Error) {
        return -1;
    }

    if (job.status === ContentProcessStatusTypes.InProgress) {
        return 75;
    }

    if (job.avatarContents?.lipsyncVideo?.status === ContentProcessStatusTypes.Completed) {
        return 50;
    }

    if (job.avatarContents?.aiVoice?.status === ContentProcessStatusTypes.Completed) {
        return 25;
    }

    return 0;
}
